import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import Main from '../components/Main'
import Button from '../components/Button'
import BlogTile from '../components/BlogTile'
import Gallery from '../components/Gallery'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import { globals, s } from '../style'

export default function CsrTemplate({ data, pageContext }) {
  const { lang } = useLangContext()
  const { setFormType } = useFormContext()

  // const blog = data.blogs.find((node) => node.lang.slug === lang) || data.blogs.find((node) => node.lang.slug === 'pl')
  const csr = data.csr

  const {
    blog_slug,
    title,
    description,
    h1,
    seo_title,
    seo_desc,
    image,
    body,
    gallery,
    keywords,
    createdAt,
    updatedAt,
  } = csr

  const button = {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read the article',
    },
    action: 'SCROLL',
  }

  const buttonBack = {
    text: {
      pl: 'Więcej inicjatyw',
      en: 'Back to all articles ',
    },
    link: '/csr/#inicjatywy',
  }

  useEffect(() => {
    setFormType('MESSAGE')
  }, [setFormType])

  return (
    <Layout>
      <Seo
        title={seo_title}
        desc={seo_desc}
        imageUrl={image.file.url}
        keywords={keywords}
        url={'blog/' + blog_slug}
        noIndex
      >
        <script type='application/ld+json' data-react-helmet='true'>{`{
    "@context": "https://schema.org",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${blog_slug}",
      "url": "https://seaandair.pl/csr/${blog_slug}/"
    },
    "headline": "${title}",
    "image": "${image.file.url}",
    "datePublished": "${createdAt}",
    "dateModified": "${updatedAt}",
    "author": {
      "@type": "Person",
      "name": "Omida Sea And Air",
      "url": "https://seaandair.pl/csr/"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Omida Sea And Air S.A.",
      "logo": {
        "@type": "ImageObject",
        "url": "https://seaandair.pl/icons/icon-512x512.png"
      }
    }
  }`}</script>
      </Seo>
      <Intro
        title={title}
        desc={description}
        button={button}
        image={image}
        position='center'
      />
      <Main
        h={1}
        title={h1}
        subtitle={'Sea & Air CSR'}
        article={body.childMarkdownRemark.html}
        // blog={body.body}
        toc={body.childMarkdownRemark.headings}
        maxWidth={globals.blogMaxWidth}
        extraCssTitle={{
          [s.lg]: {
            maxWidth: '100%',
          },
        }}
        csr
      >
        {gallery && <Gallery images={gallery} inner />}
        {/* {others && (
          <div css={sRelatedWrapper}>
            <h2>Przeczytaj również</h2>
            <div css={sBlogs}>
              {others.map((other, id) => (
                <BlogTile key={id} data={other} />
              ))}
            </div>
          </div>
        )} */}
        <Button link={buttonBack.link}>{buttonBack.text[lang]}</Button>
      </Main>
    </Layout>
  )
}

const sRelatedWrapper = {
  marginTop: '6rem',
}

const sBlogs = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '4rem',
  margin: '4rem 0',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '2rem',
    margin: '4rem 0 2rem',
  },
}

export const query = graphql`
  query ($slug: String!) {
    csr: contentfulCsr(blog_slug: { eq: $slug }) {
      createdAt
      updatedAt
      blog_slug
      title
      description
      seo_title
      seo_desc
      h1
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(
          layout: FIXED
          quality: 100
          placeholder: BLURRED
          formats: [WEBP, AVIF]
        )
        file {
          url
        }
      }
      gallery {
        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [WEBP, AVIF])
        width
        url
        height
      }
    }
  }
`
